<template>
  <div>
    <div class="container-payee container-fluid mt--6">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row justify-content-md-center">
            <div class="col-sm-12 text-center">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/payee' }"
                  >Manage Payee</el-breadcrumb-item
                >
                <el-breadcrumb-item>{{
                  $titlePayeeType($route.params.currency)
                }}</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="dataTableReject.length > 0">
        <div class="col-sm-12">
          <div class="card mb-3">
            <div class="card-header position-relative">
              <h3 class="mb-0 text-red">Reject Payees</h3>
            </div>

            <div class="card-body">
              <el-table
                size="mini"
                v-if="!isMobile"
                v-loading="loading"
                :data="dataTableReject"
                border
                style="width: 100%"
              >
                <el-table-column
                  prop="atwcurrency"
                  label="Cuurency"
                  header-align="center"
                  width="80"
                  align="center"
                  fixed="left"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.atwcurrency === ""
                        ? scope.row.currency
                        : scope.row.atwcurrency
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="Payee"
                  header-align="center"
                  width="200"
                  fixed="left"
                ></el-table-column>
                <el-table-column
                  prop="accountnumber"
                  label="Acc No./ IBAN"
                  header-align="center"
                  align="center"
                  width="250"
                ></el-table-column>
                <el-table-column
                  prop="sortcode"
                  label="Sort Code / BIC"
                  header-align="center"
                  align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="currency"
                  label="Swift"
                  header-align="center"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    {{ scope.row.currency === "SWT" ? "Yes" : "-" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="reference"
                  label="Reference"
                  header-align="center"
                  min-width="180"
                ></el-table-column>
                <el-table-column
                  prop="reason"
                  label="Reason"
                  header-align="center"
                  min-width="180"
                ></el-table-column>
                <el-table-column
                  prop="status"
                  label="Status"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-tag size="mini" type="danger">
                      {{ scope.row.status.toUpperCase() }}
                    </el-tag></template
                  >
                </el-table-column>
                <el-table-column
                  prop="uid"
                  label="Action"
                  header-align="center"
                  align="center"
                  width="180"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      class="btn-mini"
                      @click="
                        showUploadDialog(
                          scope.row.beneficiaryId,
                          scope.row.reason
                        )
                      "
                      >Upload</el-button
                    >
                    <el-button
                      type="warning"
                      class="btn-mini"
                      @click="editPayee(scope.row.uid)"
                      >Edit</el-button
                    >
                    <el-button
                      type="danger"
                      class="btn-mini"
                      @click="deletePayee(scope.row)"
                      >Del</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>

              <div class="list-group m--2" v-if="isMobile" v-loading="loading">
                <div
                  class="list-group-item"
                  v-for="(item, index) of dataTable"
                  :key="index"
                >
                  <div class="payee-content">
                    <div class="payee-title">
                      <b>{{ item.name }}</b>
                    </div>
                    <div class="payee-currency">
                      <b>Currency : </b
                      >{{
                        item.atwcurrency === ""
                          ? item.currency
                          : item.atwcurrency
                      }}
                    </div>
                    <div class="payee-acc-number">
                      <b
                        >{{
                          ["gbp", "thb"].includes(SwiftType)
                            ? "Acc No."
                            : "IBAN"
                        }}
                        :</b
                      >
                      {{ item.accountnumber }}
                    </div>
                    <div class="payee-sortcode" v-if="item.sortcode">
                      <b>{{ SwiftType === "gbp" ? "Sort Code" : "BIC" }} :</b>
                      {{ item.sortcode }}
                    </div>
                    <div class="payee-reference">
                      <b>Reference :</b> {{ item.reference }}
                    </div>
                  </div>
                  <div class="text-right">
                    <el-button
                      type="primary"
                      class="btn-mini"
                      @click="
                        showUploadDialog(
                          scope.row.beneficiaryId,
                          scope.row.reason
                        )
                      "
                      >Upload</el-button
                    >
                    <el-button
                      type="warning"
                      class="btn-mini"
                      @click="editPayee(item.uid)"
                      >Edit</el-button
                    >
                    <el-button
                      type="danger"
                      class="btn-mini"
                      @click="deletePayee(item)"
                      >Del</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card mb-3">
            <div class="card-header position-relative">
              <h3 class="mb-0">Manage Payees</h3>
              <div class="position-absolute top-2 right-2 pt-1 pr-1">
                <el-button type="success" @click="addPayee()"
                  >Add Payee</el-button
                >
              </div>
            </div>

            <div class="card-body">
              <el-alert
                v-if="
                  beneficiaryControl &&
                  ['gbp', 'eur'].includes(Currency) &&
                  !allActive
                "
                title="Pending Beneficent"
                type="error"
                description="Please upload documents shown how to relating with beneficent account i.e. invoices, business agreement etc. for allow our compliance to review."
              >
              </el-alert>
              <div class="text-xs mb-3 mt-3">
                Here you can pay, view, edit and delete existing payees. You can
                also add a new payee but make sure you enter their details
                correctly so your payments reach the right person.
              </div>
              <div style="margin-bottom: 16px">
                <el-input
                  class="input-search"
                  placeholder="Please input"
                  v-model="searchValue"
                >
                  <el-select
                    v-model="searchBy"
                    slot="prepend"
                    placeholder="Select"
                  >
                    <el-option label="Search Name" value="name"></el-option>
                    <el-option
                      label="Search Account number"
                      value="accountnumber"
                    ></el-option>
                  </el-select>
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="searchPayeeBy"
                    :loading="searchLoading"
                  ></el-button>
                </el-input>
              </div>

              <div v-if="multipleSelection.length > 0">
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  class="btn-delete-bulk"
                  @click="deleteBulk"
                  >Delete Bulk ({{ multipleSelection.length }})</el-button
                >
              </div>
              <el-table
                size="mini"
                v-if="!isMobile"
                v-loading="loading"
                :data="dataTable"
                border
                style="width: 100%"
                @selection-change="selectBulk"
              >
                <el-table-column
                  type="selection"
                  header-align="center"
                  align="center"
                  width="50"
                ></el-table-column>
                <el-table-column
                  prop="atwcurrency"
                  label="Cuurency"
                  header-align="center"
                  width="80"
                  align="center"
                  fixed="left"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.atwcurrency === ""
                        ? scope.row.currency
                        : scope.row.atwcurrency
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="Payee"
                  header-align="center"
                  width="200"
                  fixed="left"
                ></el-table-column>
                <el-table-column
                  prop="accountnumber"
                  label="Acc No./ IBAN"
                  header-align="center"
                  align="center"
                  width="250"
                ></el-table-column>
                <el-table-column
                  prop="sortcode"
                  label="Sort Code / BIC"
                  header-align="center"
                  align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="currency"
                  label="Swift"
                  header-align="center"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    {{ scope.row.currency === "SWT" ? "Yes" : "-" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="reference"
                  label="Reference"
                  header-align="center"
                  min-width="180"
                ></el-table-column>
                <el-table-column
                  prop="status"
                  label="Status"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 'active'" size="mini">
                      {{ scope.row.status.toUpperCase() }}
                    </el-tag>
                    <el-tag
                      v-if="scope.row.status == 'pending'"
                      size="mini"
                      type="warning"
                    >
                      {{ scope.row.status.toUpperCase() }}
                    </el-tag>
                    <el-tag
                      v-if="scope.row.status == 'reject'"
                      size="mini"
                      type="danger"
                    >
                      {{ scope.row.status.toUpperCase() }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="
                    beneficiaryControl &&
                    ['gbp', 'eur'].includes(Currency) &&
                    !allActive
                  "
                  prop="status"
                  label="Upload"
                  align="center"
                  min-width="200"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="
                        ['pending'].includes(scope.row.status) &&
                        !scope.row.hasFile
                      "
                      type="primary"
                      class="btn-mini"
                      @click="
                        showUploadDialog(
                          scope.row.beneficiaryId,
                          scope.row.reason
                        )
                      "
                      >Upload Document</el-button
                    >
                    <el-tag
                      v-if="
                        ['pending'].includes(scope.row.status) &&
                        scope.row.hasFile
                      "
                      size="mini"
                      type="warning"
                    >
                      Waiting for compliance reviews
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="uid"
                  label="Action"
                  header-align="center"
                  align="center"
                  width="180"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="!['pending', 'reject'].includes(scope.row.status)"
                      type="success"
                      class="btn-mini"
                      @click="makePayment(scope.row.uid, scope.row.atwcurrency)"
                      >Pay</el-button
                    >

                    <el-button
                      type="warning"
                      class="btn-mini"
                      @click="editPayee(scope.row.uid)"
                      >Edit</el-button
                    >
                    <el-button
                      type="danger"
                      class="btn-mini"
                      @click="deletePayee(scope.row)"
                      >Del</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>

              <div class="list-group m--2" v-if="isMobile" v-loading="loading">
                <div
                  class="list-group-item"
                  v-for="(item, index) of dataTable"
                  :key="index"
                >
                  <div class="payee-content">
                    <div class="payee-title">
                      <b>{{ item.name }}</b>
                    </div>
                    <div class="payee-currency">
                      <b>Currency : </b
                      >{{
                        item.atwcurrency === ""
                          ? item.currency
                          : item.atwcurrency
                      }}
                    </div>
                    <div class="payee-acc-number">
                      <b
                        >{{
                          ["gbp", "thb"].includes(SwiftType)
                            ? "Acc No."
                            : "IBAN"
                        }}
                        :</b
                      >
                      {{ item.accountnumber }}
                    </div>
                    <div class="payee-sortcode" v-if="item.sortcode">
                      <b>{{ SwiftType === "gbp" ? "Sort Code" : "BIC" }} :</b>
                      {{ item.sortcode }}
                    </div>
                    <div class="payee-reference">
                      <b>Reference :</b> {{ item.reference }}
                    </div>
                  </div>
                  <div class="text-right">
                    <el-button
                      v-if="!['pending', 'reject'].includes(item.status)"
                      type="success"
                      class="btn-mini"
                      @click="makePayment(item.uid, item.atwcurrency)"
                      >Pay</el-button
                    >

                    <el-button
                      type="warning"
                      class="btn-mini"
                      @click="editPayee(item.uid)"
                      >Edit</el-button
                    >
                    <el-button
                      type="danger"
                      class="btn-mini"
                      @click="deletePayee(item)"
                      >Del</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="Upload Document"
      :visible.sync="dialogUpload"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
    >
      <div>
        <el-alert
          v-if="reason"
          title="Reason"
          type="warning"
          :description="reason"
          :closable="false"
          show-icon
        >
        </el-alert>
        <div class="mt-2"></div>
        <UploadDocument v-on:onChange="onChangeFiles" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogUpload = false">Cancel</el-button>
        <el-button type="primary" @click="uploadFiles()" v-loading="uploading"
          >Upload Document</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PayeeApi from "@/api/payee";
import { isMobile } from "@/utils/helpers";
import Auth from "@/api/auth";
import UploadDocument from "./components/UploadDocument";
import axios from "axios";
export default {
  components: {
    UploadDocument,
  },
  computed: {
    viewCurrency() {
      return this.$route.params.currency.toUpperCase();
    },
    Currency() {
      return this.$route.params.currency;
    },
    SwiftType() {
      return this.$route.params.type;
    },
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      dialogUpload: false,
      uploading: false,
      dataTable: [],
      dataTableReject: [],
      actionUpload: "",
      headerUpload: {
        authorization: Auth.getToken(),
      },
      countuploaded: 0,
      documentLists: [],
      beneficiaryId: "",
      reason: "",
      fileFail: null,
      files: [],
      beneficiaryControl: false,
      allActive: true,
      multipleSelection: [],
      searchValue: "",
      searchBy: "name",
      searchLoading: false,
    };
  },
  methods: {
    getPayee() {
      this.allActive = true;
      this.loading = true;
      this.dataTable = [];
      this.dataTableReject = [];
      PayeeApi.getByCurrency(this.Currency, this.SwiftType).then(
        ({ result, data }) => {
          this.loading = false;
          if (result) {
            data.forEach((item) => {
              if (item.status != "active") {
                this.allActive = false;
              }
              if (item.status == "reject") {
                this.dataTableReject.push(item);
              } else {
                this.dataTable.push(item);
              }
            });
            //this.dataTable = data;
          }
        }
      );
    },
    addPayee() {
      this.$router.push(`/payee/add/${this.Currency}/${this.SwiftType}`);
    },
    editPayee(uid) {
      this.$router.push(
        `/payee/edit/${this.Currency}/${this.SwiftType}/${uid}`
      );
    },
    makePayment(uid, currency) {
      let queryCuurency = "";
      if (currency) {
        queryCuurency = `&currency=${currency}&type=${this.SwiftType}`;
      }
      this.$router.push(
        `/payment/${this.Currency}?payee=${uid}${queryCuurency}`
      );
    },
    deletePayeeApi(data) {
      PayeeApi.delPayee(this.Currency, this.SwiftType, data.uid).then(
        ({ result, message }) => {
          if (result) {
            this.$swal(
              `Message`,
              `Delete Payee <b>${data.name}</b> is success`,
              "success"
            ).then(() => {
              this.getPayee();
            });
          } else {
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    deletePayee(data) {
      this.$swal({
        title: "Delete Payee",
        html: `This will delete payee <b>${data.name}</b>. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Comfirm",
        cancelButtonText: "Cancel",
        showCloseButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deletePayeeApi(data);
        } else {
          this.$message(`Remove payee ${data.name} Canceled`);
        }
      });
    },
    showUploadDialog(beneficiaryId, reason) {
      this.dialogUpload = true;
      this.actionUpload = `${process.env.VUE_APP_SERVER_BASE_API}/api/services/v1/payee/documents/${beneficiaryId}`;
      this.beneficiaryId = beneficiaryId;
      this.reason = reason;
    },
    // okDialog() {
    //   this.dialogUpload = false;
    //   this.getPayee();
    // },
    // onErrorUpload(error, file) {
    //   file.name = "Upload fail - " + file.name;
    //   file.status = "fail";
    //   this.documentLists.push(file);
    // },
    // onSuccessUpload(response, file, fileList) {
    //   if (!response.result) {
    //     file.name = "Upload fail - " + file.name;
    //     file.status = "fail";
    //     this.$message({
    //       message: response.message,
    //       type: "error",
    //       duration: 5 * 1000,
    //     });
    //   }
    // },
    checkBeneControl() {
      PayeeApi.checkBeneControl().then(({ result, data }) => {
        if (result) {
          this.beneficiaryControl = data.beneficiaryControl;
        }
      });
    },
    onChangeFiles(files) {
      this.files = files;
    },
    uploadFiles() {
      this.uploading = true;
      const files = this.files;
      const formData = new FormData();
      formData.append("type", "upload");
      files.forEach((file) => {
        formData.append("beneficiary_files", file);
      });
      let url = `${process.env.VUE_APP_SERVER_BASE_API}/api/services/v1/payee/documents/${this.beneficiaryId}`;
      axios({
        method: "POST",
        url: url,
        data: formData,
        headers: {
          authorization: Auth.getToken(),
        },
      })
        .then((response) => {
          this.uploading = false;
          if (response.data.result) {
            this.$swal(`Message`, `Upload document is success`, "success").then(
              () => {
                this.files = [];
                this.dialogUpload = false;
                this.beneficiaryId = "";
                this.reason = "";
                this.getPayee();
              }
            );
          } else {
            this.$swal("Message", response.data.message, "error");
          }
        })
        .catch((error) => {
          this.uploading = false;
        });
    },
    searchPayeeBy() {
      if (!this.searchValue) {
        this.getPayee();
        return false;
      }
      this.searchLoading = true;
      this.allActive = true;
      this.loading = true;
      this.dataTable = [];
      this.dataTableReject = [];
      PayeeApi.searchBy(
        this.Currency,
        this.SwiftType,
        this.searchBy,
        this.searchValue
      )
        .then(({ result, data }) => {
          this.loading = false;
          this.searchLoading = false;
          if (result) {
            data.forEach((item) => {
              if (item.status != "active") {
                this.allActive = false;
              }
              if (item.status == "reject") {
                this.dataTableReject.push(item);
              } else {
                this.dataTable.push(item);
              }
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.searchLoading = false;
        });
    },
    selectBulk(selects) {
      this.multipleSelection = [];
      selects.forEach((item) => {
        this.multipleSelection.push(item.uid);
      });
    },
    deleteBulk() {
      this.$swal({
        title: "Delete Payee",
        html: `This will bulk delete the payee. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Comfirm",
        cancelButtonText: "Cancel",
        showCloseButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          PayeeApi.bulkDeletePayee(
            this.Currency,
            this.SwiftType,
            this.multipleSelection
          )
            .then(({ result, message }) => {
              if (result) {
                this.getPayee();
                this.$message({
                  type: "success",
                  message: message,
                });
              } else {
                this.$message({
                  type: "error",
                  message: message,
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: "Delete error",
              });
            });
        } else {
          this.$message(`Remove payee ${data.name} Canceled`);
        }
      });
   
    },
  },
  created() {
    this.checkBeneControl();
    this.getPayee();
  },
};
</script>

<style lang="scss">
.container-payee {
  .payee-title {
    font-size: 12px;
    color: #004b6a;
  }

  .payee-content {
    padding: 5px 10px;
  }

  .payee-currency,
  .payee-acc-number,
  .payee-sortcode,
  .payee-reference {
    font-size: 12px;
    color: #467c94;
  }

  .input-search {
    .el-input-group__prepend {
      .el-select .el-input {
        width: 150px;
      }
    }
    .el-input-group__append {
      button.el-button {
        color: #fff !important;
        background-color: #0086b9 !important;
        border-color: #0086b9 !important;
      }
    }
  }

  .btn-delete-bulk {
    position: relative;
    margin-bottom: 16px;
    &::after {
      content: "";
      position: absolute;
      border-width: 10px;
      border-style: solid;
      border-color: #f56c6c transparent transparent transparent;
      bottom: -20px;
      left: 10%;
      transform: translateX(-10%);
    }
  }
}
.el-upload-list__item.is-fail {
  a {
    color: red;

    i {
      color: red;
    }
  }
}
</style>
