<template>
  <div>
    <span class="alert-text"
      >Please upload documents shown how to relating with beneficent account
      i.e. invoices, business agreement etc</span
    >
    <div class="upload-document">
      <div
        class="dropzone-container mb-4"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onSelected"
          ref="file"
          accept=".pdf,.jpg,.jpeg,.png"
        />

        <label for="fileInput" class="file-label">
          <div v-if="isDragging">Release to drop files here.</div>
          <div v-else>Drop your PDF, PNG or JPEG files here! Up to 5 files<br> or <u>click here</u> to upload.</div>
        </label>

        <!-- Note: Only add the code block below -->
        <div class="preview-container" v-if="files.length">
          <div v-for="file in files" :key="file.name" class="preview-card">
            <el-image
              style="width: 100px; height: 100px"
              :src="generateURL(file)"
              fit="cover"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-document"></i>
              </div>
            </el-image>
            <p class="file-info">
              {{ limitFileName(file.name) }} -
              {{ Math.round(file.size / 1000) + "kb" }}
            </p>
            <div>
              <button
                class="ml-2 btn btn-danger btn-sm"
                type="button"
                @click="remove(files.indexOf(file))"
                title="Remove file"
              >
                <b>×</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDragging: false,
      files: [],
      fileAccept: [
        'application/pdf',
        'image/jpeg',
        'image/png'
      ],
      limit: 5
    };
  },
  methods: {
    onChange() {
      this.$emit("onChange", this.files);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    onSelected(){
     this.checkFile(this.$refs.file.files);
    },
    drop(e) {
      e.preventDefault();
      this.checkFile(e.dataTransfer.files);
      this.isDragging = false;
    },
    checkFile(files){
      Array.from(files).forEach(file=>{
        if(this.files.length >= this.limit){
          this.$swal("Message", "You can only upload a maximum of 5 files", "error");
        }else{
          if(this.fileAccept.includes(file.type)){
            this.files.push(file);
          }
        }
      });
       this.onChange();
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    limitFileName(name){
  
      var ext = name.substring(name.lastIndexOf('.') + 1);//getting file extension
      var fileName = name.substring(0,name.length-4); // storing 0th position till extension begining

      var fileNameNew=""; 
      if(fileName.length > 10)  
      {
         var fileNameFst=fileName.substring(0,5); //firstpart of file
         var fileNameLst=fileName.substring(fileName.length-5,fileName.length); //last part of file
         fileNameNew=fileNameFst+ "..." + fileNameLst+"."+ext; //combine all parts         
      }         
      else
      {         
         fileNameNew=fileName+"."+ext; //if length less than 30 
      } 
      
      return fileNameNew;
    }
  },
};
</script>

<style lang="scss">
.alert-text {
  font-size: 12px;
  color: red;
}
.upload-document {
  .dropzone-container {
    padding: 1rem;
    background: #f7fafc;
    border: 1px solid #e2e8f0;
    text-align: center;
    min-height: 150px;
  }

  .hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .file-label {
    font-size: 14px;
    display: block;
    cursor: pointer;
  }

  .preview-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    * {
      flex: 1 1 160px;
    }
  }
  .file-info{
    font-size: 12px;
  }
  .image-slot {
    font-size: 3rem;
  }

  .preview-card {
    border: 1px solid #a2a2a2;
    padding: 5px;
    margin-left: 5px;
  }

  .preview-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #a2a2a2;
    background-color: #a2a2a2;
  }
}
</style>